import React, { useState, useEffect } from 'react';
import { ref, onValue, child } from 'firebase/database';
import { dbRealtime } from './firebase'; // Import the Realtime Database
import { Navbar } from './navbar';
import "./Show.css";

function Showsms() {
  const [tableData, setTableData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20; // Number of items per page

  useEffect(() => {
    document.title = "Page";
  }, []);

  // Load all data from Firebase on initial load
  useEffect(() => {
    const dataRef = ref(dbRealtime, 'ReceivedSMS'); // Reference to the 'ReceivedSMS' path in your database

    const handleDataChange = (snapshot) => {
      if (snapshot.exists()) {
        const dataObj = snapshot.val();
        setTableData(dataObj);  // Set all data initially to local state
      } else {
        console.log('No data available.');
      }
    };

    // Load the initial data
    const dataListener = onValue(dataRef, handleDataChange);

    return () => {
      dataListener(); // Unsubscribe the listener on component unmount
    };
  }, []);

  // Handling new data from Firebase after initial load
  useEffect(() => {
    const newDataRef = ref(dbRealtime, 'ReceivedSMS'); // Reference to the 'ReceivedSMS' path

    const handleNewData = (snapshot) => {
      if (snapshot.exists()) {
        const newData = snapshot.val();

        // Only update if new data is added (you could check for timestamps or keys to ensure uniqueness)
        setTableData(prevData => ({
          ...prevData,
          ...newData, // Add new data to the existing data
        }));
      }
    };

    // Listen for new child added
    const newDataListener = onValue(newDataRef, handleNewData);

    return () => {
      newDataListener(); // Unsubscribe the listener
    };
  }, []);

  // Reversed table data and pagination logic
  const reversedTableData = Object.entries(tableData).reverse();
  const totalItems = reversedTableData.length;

  // Logic to paginate data
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPageData = reversedTableData.slice(startIndex, endIndex);

  // Handle page change
  const handleNextPage = () => {
    if (currentPage * itemsPerPage < totalItems) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  return (
    <div>
      <Navbar />
      <h1 style={{ textAlign: 'center', marginTop: '-5px', fontSize: '25px' }}>SMS</h1>
      <p style={{ textAlign: 'center', color: 'red', padding: '0px', marginTop: '-20px' }}>show here</p>
      <div style={{ marginLeft: '10px', marginRight: '10px' }}>
        {currentPageData.map(([key, value], index) => (
          <div key={index} className='box'>
            {Object.entries(value).map(([dataKey, dataValue], dataIndex) => (
              <p key={dataIndex}>
                {dataKey.charAt(0).toUpperCase() + dataKey.slice(1)}: {dataValue}
              </p>
            ))}
          </div>
        ))}
      </div>

      {/* Pagination Controls */}
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <button onClick={handlePrevPage} disabled={currentPage === 1}>
          Previous
        </button>
        <span style={{ margin: '0 10px' }}>Page {currentPage}</span>
        <button onClick={handleNextPage} disabled={currentPage * itemsPerPage >= totalItems}>
          Next
        </button>
      </div>
    </div>
  );
}

export default Showsms;
